<template>
    <div id="assetsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="assets"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_assetListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <router-link :to="{ name: 'newAsset' }" id="assets_createNewAssetButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("asset_createNewAsset") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="assets" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <i class="kt-font-brand flaticon-list-2"></i>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("asset_assetList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByNameOrCat')" id="assets_searchInput" @keyup="onSearch" v-model="searchedValue" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button @click="exportAssetModal" id="assets_exportTagsFromXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                        </div>

                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="ASSET" mainTextKey="asset_assetNotFound" subTextKey="asset_assetNotFoundSubLabel" imgName="empty_asset.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->

                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="assets_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteAssetsTable: null,
            siteAssetsData: [],
            searchedValue: "",
            isEmptyList: false,
            isMounted: false
        };
    },
    created: function() {
        console.log("Component(Assets)::created() - called");
        if (this.createAnotherAsset) {
            this.$router.push({ name: "newAsset" });
        } else {
            this.getSiteById(this.siteId);
            this.getSiteAssets(this.siteId);
        }
    },
    mounted: function() {
        console.log("Component(Assets)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Assets)::destroyed() - called");
        this.resetAssetsState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        siteAssets: function(assets) {
            console.log("Component(Assets)::watch(siteAssets) called with : ", assets);
            if (assets) {
                this.siteAssetsData = this.buildDatatableData();
                this.initKtDataTable(this.siteAssetsData);
            }
        },
        user: function(user) {
            console.log("Component(Assets)::watch(user) called with :", user);
            if (user) {
                this.siteAssetsData = this.buildDatatableData();
                this.initKtDataTable(this.siteAssetsData);
            }
        },
        currentSite: function(site) {
            console.log("Component(Assets)::watch(currentSite) called with :", site);
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAssets", "currentSite", "createAnotherAsset", "user", "hasAccess"]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            }
            return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteById", "getSiteAssets", "resetAssetsState", "showDeleteModal", "createAssetIQMDeviceCode", "updateAssetIQMDeviceCode", "updateAssetState"]),

        onSearch() {
            if (this.siteAssetsTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // To search EITHER by name OR by tag OR by category:
                // 1. Search in "name" field of assets data
                const dataFoundByName = _.filter(this.siteAssetsData, asset => asset && asset.name && asset.name.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "tagSN" field of assets data
                const dataFoundByTag = _.filter(this.siteAssetsData, asset => asset && asset.tagSN && asset.tagSN.toUpperCase().includes(searchedValueUpperCase));
                // 3. Search in "categoryName" field of assets data
                const dataFoundByCategory = _.filter(this.siteAssetsData, asset => asset && asset.categoryName && asset.categoryName.toUpperCase().includes(searchedValueUpperCase));
                // 4. Create an array with previous unique resulting searched
                let dataFound = _.unionBy(dataFoundByName, dataFoundByTag, 'id');
                dataFound = _.unionBy(dataFound, dataFoundByCategory, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.siteAssetsTable.getPageSize());
                } else {
                    // No searchedValue found => Display empty datatable
                    this.siteAssetsTable.search(this.searchedValue, "unknown");
                }
            }
        },

        onEditAsset: function(assetId) {
            this.$router.push({ name: "editAsset", params: { assetId: assetId } });
        },

        onEditTag: function(tagId) {
            this.$router.push({ name: "editTag", params: { tagId: tagId, siteId: this.siteId } });
        },

        onSeeAssetOnMap: function(tagMacAddress) {
            this.$router.push({ name: "assetsTrackingDashboard", query: { highlight: tagMacAddress } });
        },

        onEditAssetCategory: function(assetCategoryId) {
            this.$router.push({ name: "editAssetCategory", params: { siteId: this.siteId, assetCategoryId: assetCategoryId } });
        },

        onCreateAssetIQMDeviceCode: function(assetId) {
            const payload = {
                siteId: this.siteId,
                assetId: assetId,
                iqmId: (this.currentSite && this.currentSite.IQMConfiguration ? this.currentSite.IQMConfiguration.id : "")
            };
            this.createAssetIQMDeviceCode(payload);
        },

        onUpdateAssetIQMDeviceCode: function(assetId) {
            const payload = {
                siteId: this.siteId,
                assetId: assetId,
                iqmId: (this.currentSite && this.currentSite.IQMConfiguration ? this.currentSite.IQMConfiguration.id : "")
            };
            this.updateAssetIQMDeviceCode(payload);
        },

        registerEventsOnSiteAssetsTable() {
            var self = this;

            // Event triggered on layout rendering update and resizing datatable.
            $("#assets_datatable").on("kt-datatable--on-layout-updated", () => {

                $(".onChangeStateRadio").off().on("change", function() {
                    let assetId = $(this).attr("data-assetid");
                    console.log("Component(Assets)::registerEventsOnSiteAssetsTable(onChangeStateRadio). AssetId = " + assetId);
                    self.updateAssetState({
                        assetId: assetId,
                        siteId: self.siteId,
                        isEnabled: this.checked
                    });
                });

                $(".deleteAssetButton")
                    .off()
                    .on("click", function() {
                        let assetId = $(this).attr("data-assetid");
                        let assetName = $(this).attr("data-assetname");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(deleteAssetButton) clicked. AssetId = " + assetId);
                        self.showDeleteModal({ textContent: i18n.t("asset_deleteAssetConfirmText", { assetName: assetName }), actionName: "DELETE_ASSET", params: { siteId: self.siteId, assetId: assetId } });
                    });

                $(".editAssetButton")
                    .off()
                    .on("click", function() {
                        let assetId = $(this).attr("data-assetid");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(editAssetButton) clicked. AssetId = " + assetId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditAsset(assetId);
                    });

                $(".editTagButton")
                    .off()
                    .on("click", function() {
                        let tagId = $(this).attr("data-tagid");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(editTagButton) clicked. TagId = " + tagId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditTag(tagId);
                    });

                $(".seeOnMapButton")
                    .off()
                    .on("click", function() {
                        let tagMacAddress = $(this).attr("data-tagmacaddress");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(seeOnMapButton) clicked. tagMacAddress = " + tagMacAddress);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onSeeAssetOnMap(tagMacAddress);
                    });

                $(".editAssetCategoryButton")
                    .off()
                    .on("click", function() {
                        let assetCategoryId = $(this).attr("data-categoryid");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(editAssetCategoryButton) clicked. Asset Category Id = " + assetCategoryId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditAssetCategory(assetCategoryId);
                    });

                $(".IQMRetryCreationButton")
                    .off()
                    .on("click", function() {
                        let assetId = $(this).attr("data-assetid");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(IQMRetryCreationButton) clicked. Asset Id = " + assetId);
                        self.onCreateAssetIQMDeviceCode(assetId);
                    });

                $(".IQMRetryUpdateButton")
                    .off()
                    .on("click", function() {
                        let assetId = $(this).attr("data-assetid");
                        console.log("Component(Assets)::registerEventsOnSiteAssetsTable(IQMRetryUpdateButton) clicked. Asset Id = " + assetId);
                        self.onUpdateAssetIQMDeviceCode(assetId);
                    });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);

            });
        },

        exportAssetModal() {
            var data = [];

            //  Creation of header
            var header = [
                {name: "Asset Name", type: "STRING"},
                {name: "Unit", type: "STRING"},
                {name: "Tag Serial Number", type: "STRING"},
                {name: "Category", type: "STRING"},
                {name: "Status", type: "STRING"}
            ];

            this.siteAssets.forEach(asset => {
                var assetInfo = [asset.name];

                if (asset.assetUnit) {
                    if (asset.assetUnit.isDefault) {
                        assetInfo.push(i18n.t(asset.assetUnit.name))
                    } else {
                        assetInfo.push(asset.assetUnit.name)
                    }
                }

                // Verify if asset is associated to tag
                if (asset.tag) {
                    assetInfo.push(asset.tag.serialNumber);
                } else {
                    assetInfo.push("-");
                }

                // Add the category of asset
                if (asset.assetcategory.isDefault) {
                    assetInfo.push(i18n.t(asset.assetcategory.name));
                } else {
                    assetInfo.push(asset.assetcategory.name);
                }

                if (!asset.tag) {
                    assetInfo.push("-");
                } else {
                    if (asset.isEnabled) {
                        assetInfo.push(i18n.t("asset_assetStatusActive"));
                    } else {
                        assetInfo.push(i18n.t("asset_assetStatusInactive"));
                    }
                }

                // Add in export data
                data.push(assetInfo);
            });
            // Call common vueHelper function
            commonVueHelper.exportsDataToExcel(data, header, "assets", "assets");
        },

        buildDatatableData() {
            // Prepare data before init datatable
            let data = [];
            if (this.siteAssets) {
                const isIQMConfigured = (this.currentSite && this.currentSite.IQMConfiguration && this.currentSite.IQMConfiguration.url ? true : false);
                data = this.siteAssets.map((asset) => {
                    let finalAsset = {...asset};
                    // Translate and add the status
                    if (asset.isEnabled) {
                        finalAsset.status = i18n.t("asset_assetStatusActive");
                    } else {
                        finalAsset.status = i18n.t("asset_assetStatusInactive");
                    }
                    // Translate role
                    if (asset.assetUnit) {
                        if (asset.assetUnit.isDefault) {
                            finalAsset.translateUnit = i18n.t(asset.assetUnit.name);
                        } else {
                            finalAsset.translateUnit = asset.assetUnit.name;
                        }
                    } else {
                        finalAsset.translateUnit = "";
                    }
                    if (asset.assetcategory && asset.assetcategory.name) {
                        let categoryName = asset.assetcategory.name;
                        if (asset.assetcategory.isDefault) {
                            finalAsset.categoryIconUrl = "/assets/categories/" + categoryName + "/Icon.png";
                            finalAsset.categoryName = i18n.t(categoryName);
                        } else {
                            finalAsset.categoryBackgroundUrl = `url(` + asset.assetcategory.iconImg + `)`;
                            const maxLength = 18;
                            if (categoryName.length > maxLength) {
                                categoryName = categoryName.substring(0, maxLength) + "...";
                            }
                            finalAsset.categoryName = categoryName;
                        }
                    }
                    if (asset.tag && asset.tag.tagType) {
                        if (asset.tag.tagType.image) {
                            finalAsset.tagTypeImg = commonVueHelper.getPropFromTagType("img", asset.tag.tagType);
                        } else {
                            finalAsset.tagTypeImg = "/assets/tags/universal.svg";
                        }
                        finalAsset.tagSN = asset.tag.serialNumber ? asset.tag.serialNumber : commonVueHelper.getPropFromTagType("fullRef", asset.tag.tagType);
                    } else {
                        finalAsset.tagSN = "-";
                    }
                    if (isIQMConfigured && asset.IQMStatus) {
                        finalAsset.translateIQMStatus = i18n.t("asset_IQM_" + asset.IQMStatus);
                    }
                    return finalAsset;
                });
            }
            return data;
        },

        initKtDataTable(data, pageSize) {
            var self = this;
            const isIQMConfigured = (this.currentSite && this.currentSite.IQMConfiguration && this.currentSite.IQMConfiguration.url ? true : false);
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: (pageSize ? pageSize : 20)
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("asset_assetName"),
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        sortable: "desc",
                        template: function(row) {
                            return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editAssetButton kt-link kt-user-card-v2__name" data-assetid="' + row.id + '" href="javascript:;">' + row.name + "</a>\
                                    </div>\
                                </div>\
                            ";
                        }
                    },
                    {
                        field: "status",
                        title: i18n.t("asset_assetStatus"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false,
                        template: function(row) {
                            if (row.tag && row.tag.id) {
                                if (row.isEnabled) {
                                    return (
                                        '<div style="margin-top:10px;"><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                        row.status +
                                        "</span>"+
                                        '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                            '<label>'+
                                                '<input class="onChangeStateRadio" checked="checked" data-assetid="'+row.id+'" type="checkbox">'+
                                                '<span></span>'+
                                            '</label>'+
                                        '</span></div>'
                                    );
                                } else {
                                    return (
                                        '<div style="margin-top:10px;"><span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' +
                                        row.status +
                                        "</span>"+
                                        '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                            '<label>'+
                                                '<input class="onChangeStateRadio" type="checkbox" data-assetid="'+row.id+'">'+
                                                '<span></span>'+
                                            '</label>'+
                                        '</span></div>'
                                    );
                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "translateUnit",
                        title: i18n.t("asset_assetUnit"),
                        textAlign: "center"
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                            }
                        }
                    },
                    {
                        field: "tagSN",
                        title: i18n.t("asset_assetTag"),
                        textAlign: "left",
                        width: 190,
                        template: function(row) {
                            if (row.tag && row.tagSN) {
                                return (
                                    `<div class="kt-user-card-v2">
                                        <img src="` + row.tagTypeImg + `" class="tagsIconPreview" />
                                        <div class="kt-user-card-v2__details" style="margin-left:8px;">
                                            <a class="editTagButton kt-link kt-user-card-v2__name openResourceLink" data-tagid="` + row.tag.id + `" href="javascript:;">` +
                                                row.tagSN +
                                            `</a>
                                        </div>
                                    </div>`
                                );

                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "categoryName",
                        title: i18n.t("asset_assetCategory"),
                        textAlign: "left",
                        autoHide: true,
                        overflow: "visible",
                        autoHide: false,
                        width: 230,
                        template: function(row) {
                            if (row.categoryIconUrl) {
                                return (
                                    `<div class="kt-user-card-v2">
                                        <img src="` + row.categoryIconUrl + `" class="assetsIconPreview" />
                                        <div class="kt-user-card-v2__details" style="margin-left:8px;">
                                            <a class="editAssetCategoryButton kt-link kt-user-card-v2__name openResourceLink" data-categoryid="` + row.assetcategory.id + `" href="javascript:;">` +
                                                row.categoryName +
                                            `</a>
                                        </div>
                                    </div>`
                                );
                            } else {
                                return (
                                    `<div class="kt-user-card-v2">
                                        <img style="background-image: ` + row.categoryBackgroundUrl + `;" class="assetsIconPreviewCustom" />
                                        <div class="kt-user-card-v2__details" style="margin-left:8px;">
                                            <a class="editAssetCategoryButton kt-link kt-user-card-v2__name openResourceLink" data-categoryid="` + row.assetcategory.id + `" href="javascript:;">` +
                                                row.categoryName +
                                            `</a>
                                        </div>
                                    </div>`
                                );
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            var locateAssetButton = "";
                            if (row.tag && row.tag.macAddress && self.hasAccess(self.siteId, 'ASSET_SEARCH.SEARCH') && row.isEnabled) {
                                locateAssetButton =
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                    i18n.t("common_seeOnMap") +
                                    `" type="button" data-assetid="` +
                                    row.id +
                                    `" data-tagmacaddress="` +
                                    row.tag.macAddress +
                                    `" class="btn btn-outline-primary btn-sm btn-icon seeOnMapButton">
                                        <i class="la la-map-marker"></i>
                                    </button>`;
                            }
                            return (
                                `
                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_edit") +
                                `" type="button" data-assetid="` +
                                row.id +
                                `" class="btn btn-outline-brand btn-sm btn-icon editAssetButton">
                                    <i class="la la-pencil"></i>
                                </button>
                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button" data-assetid="` +
                                row.id +
                                `" data-assetname="` +
                                row.name +
                                `" class="btn btn-outline-danger btn-sm btn-icon deleteAssetButton">
                                    <i class="la la-trash"></i>
                                </button>
                                ` +
                                locateAssetButton +
                                `
                            `
                            );
                        }
                    }
                ]
            };

            if (isIQMConfigured) {
                // Add IQM status column just before the last column
                const indexLastColumn = options.columns.length - 1;
                const IQMStatusColumn = {
                    field: "translateIQMStatus",
                    title: i18n.t("asset_IQM_status"),
                    width: 220,
                    overflow: "visible",
                    autoHide: false,
                    textAlign: "left",
                    template: function(row) {
                        if (row.IQMStatus) {
                            let htmlButton = "";
                            let classStatus = "default";
                            switch (row.IQMStatus) {
                                case "DECLARED":
                                    classStatus = "success"; // green
                                    break;
                                case "BEING_DECLARED":
                                case "BEING_UPDATED":
                                    classStatus = "warning"; // orange
                                    break;
                                case "NOT_UPDATED":
                                    classStatus = "warning"; // orange
                                    htmlButton = '<button type="button" class="btn btn-outline-brand btn-sm marginL15 IQMRetryUpdateButton" data-assetid="' + row.id + '"><i class="la la-refresh"></i>' + i18n.t("asset_IQM_retry") + '</button>';
                                    break;
                                case "FAILED":
                                    classStatus = "danger"; // red
                                    htmlButton = ' <button type="button" class="btn btn-outline-brand btn-sm marginL15 IQMRetryCreationButton" data-assetid="' + row.id + '"><i class="la la-refresh"></i>' + i18n.t("asset_IQM_retry") + '</button>';
                                    break;
                            }
                            return `
                                <span class="kt-badge kt-badge--` + classStatus + ` kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-` + classStatus + `">` +
                                    row.translateIQMStatus +
                                `</span>` +
                                htmlButton;
                        } else {
                            return "-";
                        }
                    }
                };
                options.columns.splice(indexLastColumn, 0, IQMStatusColumn);
            }

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteAssetsTable) {
                $("#assets_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteAssetsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data && options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteAssetsTable = $("#assets_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteAssetsTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
            }
        },

        showOrHideKTDatatableColumn(show, columnName) {
            if (this.siteAssetsTable) {
                if (show) {
                    // Show the column
                    this.siteAssetsTable.showColumn(columnName);
                } else {
                    // Hide the column
                    this.siteAssetsTable.hideColumn(columnName);
                }
            }
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
<style>
.floatLeft {
    float: left;
}
.displayGrid {
    display: grid !important;
}
.subMacAddress {
    color: #1b1ba9;
    font-size: 11px;
}
.marginT8 {
    margin-top: 8px;
}
.marginT4 {
    margin-top: 4px;
}
.assetsIconPreview {
    height: 45px;
    width: 45px;
}
.assetsIconPreviewCustom {
    height: 45px;
    width: 45px;
    background-position: center center;
    background-size: cover;
    border-radius: 300px;
}
</style>
